export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [5],
		"/[country=any_country]/[language=any_language]/_crp_": [7,[2]],
		"/[country=any_country]/[language=any_language]/_crp_/email": [8,[2]],
		"/[country=any_country]/[language=any_language]/_crp_/logout": [9,[2]],
		"/[country=any_country]/[language=any_language]/builder-section-preview/[model_name]/[[entry_id]]": [10,[3]],
		"/[country=any_country]/[language=any_language]/digital-entitlement": [11,[4]],
		"/[country=any_country]/[language=any_language]/digital-entitlement/activate-code": [12,[4]],
		"/[country=any_country]/[language=any_language]/digital-entitlement/contact-address-info": [~13,[4]],
		"/[country=any_country]/[language=any_language]/digital-entitlement/redeem-service-code": [14,[4]],
		"/[country=any_country]/[language=any_language]/insights": [15],
		"/[country=any_country]/[language=any_language]/insights/page/[...catchall]": [17],
		"/[country=any_country]/[language=any_language]/insights/post/[...rest]": [18],
		"/[country=any_country]/[language=any_language]/insights/[...catchall]": [16],
		"/[country=any_country]/[language=any_language]/schneider-in-america": [19],
		"/[country=any_country]/[language=any_language]/techcomm-edit-symbol/[...catchall]": [21],
		"/[country=any_country]/[language=any_language]/techcomm/[...catchall]": [20],
		"/[country=any_country]/[language=any_language]/[...catchall]/[...catchall]": [~6]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';